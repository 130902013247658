import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import versionTood from "@/versionUpdate";

// import Home1 from "../views/Home1.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    // 银行卡预览
    path: "/bank_list",
    name: "bankList",
    component: () => import("../views/bankList/index.vue"),
    meta:{sevrice:true}

    // component: () => import("../views/Home.vue")
  },
  {
    // 首页改版
    path: "",
    name: "home",
    component: () => import("../views/Home4.vue"),
    meta:{keepneed:true}
    // component: () => import("../views/Home.vue")
  },

  {
    // 首页显示沪金
    path: "/home",
    name: "home3",
    component: () => import("../views/Home3.vue"),
    meta:{keepneed:true}
    // component: () => import("../views/Home.vue")
  },

  {
    // 关于我们
    path: "/aboutUs",
    name: "aboutUs",
    component: () => import("../views/aboutUs.vue")
    // component: () => import("../views/Home.vue")
  },
  {
    // 每日报价单
    path: "/quoted",
    name: "quoted",
    component: () => import("../views/quoted.vue")
  },
  {
    // 买金
    path: "/Buy_gold",
    name: "Buygold",
    component: () => import("../views/buy_gold/index.vue"),

  },
  {
    // 存金
    path: "/mortgagegold",
    name: "mortgagegold",
    component: () => import("../views/mortgagegold/index.vue"),

  },
  // {
  //   // 首页
  //   path: "",
  //   name: "home",
  //   component: () => import("../views/Home.vue")
  // },
  {
    // 登录
    path: "/login",
    name: "login",
    component: () => import("../views/login/index.vue"),
    meta:{sevrice:true,suspends:true}
  },
  {
    // 退出登录
    path: "/loginOut",
    name: "login_out",
    component: () => import("../views/person/login_out.vue")
  },
  {
    // 注册
    path: "/register",
    name: "register",
    component: () => import("../views/register/index.vue"),
    meta:{suspends:true}
  },

  {
    // 协议
    path: "/policyList",
    name: "policyList",
    component: () => import("../views/policy/policyList.vue")
  },

  {
    // 协议列表
    path: "/policy",
    name: "policy",
    component: () => import("../views/policy/policy.vue")
  },

  {
    // 忘记密码
    path: "/respas",
    name: "respas",
    component: () => import("../views/resPassword/index.vue")
  },
  {
    // 绑定手机号
    path: "/bindphone",
    name: "bindingPhone",
    component: () => import("../views/bindingPhone/index.vue")
  },
  {
    // 消息中心
    path: "/message",
    name: "message",
    component: () => import("../views/message/index.vue")
  },
  {
    // 消息详情
    path: "/messageDeatils",
    name: "messageDeatils",
    component: () => import("../views/message/msg_details.vue")
  },
  {
    // 实名认证
    path: "/certification",
    name: "certification",
    component: () => import("../views/certification/index.vue")
  },
  {
    // 上门取货
    path: "/pickUp",
    name: "pickUp",
    component: () => import("../views/pickUp/index.vue")
  },
  {
    // 上门取货-地址详情
    path: "/pickUpdetails",
    name: "pickUp_details",
    component: () => import("../views/pickUp/pickUp_details.vue")
  },
  {
    // 实名认证-用户信息
    path: "/usercerti",
    name: "usercerti",
    component: () => import("../views/certification/user_details.vue")
  },
  {
    // 个人中心
    path: "/person",
    name: "person",
    component: () => import("../views/person/index.vue")
  },
  // {
  //   // 在线客服
  //   path: "/service",
  //   name: "service",
  //   component: () => import("../views/service/index.vue"),
  //   meta: { needKeep: true }
  // },
  {
    // 新闻列表
    path: "/news",
    name: "news",
    component: () => import("../views/newsCopy/index.vue"),
    meta:{sevrice:true,suspends:true}
  },
  {
    // 新闻列表
    path: "/new",
    name: "new",
    component: () => import("../views/news/index.vue"),
    meta:{keepneed:true}
},
  {
    // 要闻内容
    path: "/newsContent",
    name: "newsContent",
    component: () => import("../views/news/components/newsContent.vue"),
    meta:{sevrice:true}
},
  {
    // 定价详情
    path: "/pricingDetails",
    name: "pricingDetails",
    component: () => import("../views/pricingDetails/index.vue")
  },
  {
    // 卖出结算
    path: "/sellSettlement",
    name: "sellSettlement",
    component: () => import("../views/sellSettlement/index.vue"),
  },
  {
    // 卖出结算详情
    path: "/selldetails",
    name: "selldetails",
    component: () => import("../views/selldetails/index.vue")
  },
  {
    // 卖出结算详情-电子表格
    path: "/selldetails_table",
    name: "selldetails_table",
    component: () => import("../views/selldetails/selldetails_table.vue")
  },
  {
    // 定价记录
    path: "/pricingRecords",
    name: "pricingRecords",
    component: () => import("../views/pricingRecords/index.vue")
  },
  {
    // 物流追踪
    path: "/logistics",
    name: "logistics",
    // component: () => import("../views/logistics/index.vue")
    component: () => import("../views/pickUp/logistics.vue")
  },
  {
    // 物流详情
    path: "/logisticsDetails",
    name: "logisticsDetails",
    component: () => import("../views/logistics/logisticsDetails.vue")
  },
  {
    // 理赔规则
    path: "/claimExplanation",
    name: "claimExplanation",
    component: () => import("../views/claim/claimExplanation.vue")
  },
  {
    // 保单详情
    path: "/claimDetails",
    name: "claimDetails",
    component: () => import("../views/claim/claimDetails.vue")
  },
  {
    // 我的保单
    path: "/claim",
    name: "claim",
    component: () => import("../views/claim/index.vue")
  },
  {
    // 填写投保信息
    path: "/claimedit",
    name: "claimedit",
    component: () => import("../views/claim/claimedit.vue")
  },
  {
    // 关于我们
    path: "/about",
    name: "about",
    component: () => import("../views/about/index.vue")
  },
  {
    // 关于我们列表
    path: "/aboutList",
    name: "aboutList",
    component: () => import("../views/about/aboutList.vue")
  },

  {
    // 常见问题
    path: "/commonProblem",
    name: "commonProblem",
    component: () => import("../views/about/commonProblem.vue")
  },
  {
    // 业务说明
    path: "/BusinessDescription",
    name: "BusinessDescription",
    component: () => import("../views/about/BusinessDescription.vue")
  },
  {
    // 银行卡管理
    path: "/bank",
    name: "bank",
    component: () => import("../views/addBank/index.vue")
  },
  {
    // 新增银行卡
    path: "/addbank",
    name: "AddBank",
    component: () => import("../views/addBank/components/addBankCard.vue")
  },
  {
    // 在线客服
    path: "/kefu",
    name: "kefu",
    component: () => import("../views/person/kefu.vue")
  },
  {
    // 邀请好友
    path: "/inviteFriends",
    name: "inviteFriends",
    component: () => import("../views/person/inviteFriends.vue")
  },
  {
    // 地址管理
    path: "/address",
    name: "address",
    component: () => import("../views/person/address.vue")
  },
  {
    // 待发货
    path: "/Delivery",
    name: "Delivery",
    component: () => import("../views/person/delivery.vue")
  },
  {
    // 地址设置
    path: "/addressEdit",
    name: "addressEdit",
    component: () => import("../views/person/addressEdit.vue")
  },
  {
    // 卖金
    path: "/sellgold",
    name: "sellgold",
    component: () => import("../views/sellgold/index.vue"),

  },
  {
    // 定价
    path: "/pricing",
    name: "pricing",
    component: () => import("../views/Pricing/index.vue")
  },
  {
    // 投保-上门取货-结算确认 成功页面
    path: "/successpage",
    name: "successpage",
    component: () => import("../views/successpage/index.vue")
  },
  {
    // 保险条款
    path: "/InsuranceClause",
    name: "InsuranceClause",
    component: () => import("../views/claim/claimedit_tiaokuan.vue")
  },
  {
    // 航空险
    path: "/claimeditHangkongxian",
    name: "claimeditHangkongxian",
    component: () => import("../views/claim/claimedit_hangkongxian.vue")
  },
  {
    // 邮包险
    path: "/claimeditdelivery",
    name:'claimeditdelivery',
    component: () => import("../views/claim/claimedit_youbaoxian.vue")
  },
  {
    // 保费对比
    path: "/baofeiduibi",
    name: "baofeiduibi",
    component: () => import("../views/claim/baofei.vue")
  },
  {
    // 用户注册协议
    path: "/registerrule",
    name: "registerrule",
    component: () => import("../views/register/rule.vue")
  },
  {
    // map
    path: "/map",
    name: "map",
    component: () => import("../views/map/index.vue")
  },
  {
    // 保费对比
    path: "*",
    name: "backhome",
    redirect: ""
  },
  {
    // 客服电话
    path: "/phone",
    name: "phone",
    component: () => import("../views/telephone/index.vue")
  },
  {
    // 定价押金
    path: "/pricingDeposit",
    name: "pricingDeposit",
    component: () => import("../views/pricingDeposit/index.vue")
  },
  {
    // 买料订单
    path: "/purchaseOrder",
    name: "purchaseOrder",
    component: () => import("../views/purchaseOrder/index.vue"),
    meta:{keepneed:true}
  },
  {
    // 买料订单
    path: "/carryApply",
    name: "carryApply",
    component: () => import("../views/buyOrder/components/tiLiaoApply.vue"),
  },
  {
    // 买料结算单
    path: "/purchaseSettlement",
    name: "purchaseSettlement",
    component: () => import("../views/purchaseSettlement/index.vue"),
  },
  {
    // 买料订单详情
    path: "/buyOrderParticulars",
    name: "buyOrderParticulars",
    component: () => import("../views/buyOrderParticulars/index.vue"),

  },
  {
    // 人脸认证
    path: "/face",
    name: "face",
    component: () => import("../views/faceAuthentication/index.vue"),
  },
  {
    // 人脸认证
    path: "/faceing",
    name: "faceing",
    component: () => import("../views/faceAuthentication/faceProtocol/face.vue"),
  },
  {
    // 人脸认证协议
    path: "/faceProtocol",
    name: "faceProtocol",
    component: () => import("../views/faceAuthentication/faceProtocol/index.vue"),
  },
  {
    // 成色表
    path: "/retrieve",
    name: "retrieve",
    component: () => import("../views/retrieve/index.vue"),
  },
  {
    // 线上回收流程
    path: "/onlineRecovery",
    name: "onlineRecovery",
    component: () => import("../views/onlineRecovery/index.vue"),
    meta:{keepneed:true}
  },
  {
    // 线上回收流程
    path: "/recovery",
    name: "recovery",
    component: () => import("../views/person/recovery.vue"),
    meta:{keepneed:true}
  },
  {
    // 存料记录
    path: "/keepRecord",
    name: "keepRecord",
    component: () => import("../views/keepRecord/index.vue"),

  },
  {
    // 提料不结价记录
    path: "/materialOrder",
    name: "materialOrder",
    component: () => import("../views/materialOrder/index.vue"),

  },
  {
    // 存料管理
    path: "/stockManage",
    name: "stockManage",
    component: () => import("../views/stockManage/index.vue"),
  },
  {
    // 提料管理
    path: "/materialManage",
    name: "materialManage",
    component: () => import("../views/materialOrder/materialManage.vue"),
  },
  {
    // 存料管理 提交结算说明页面
    path: "/submitStockExplain",
    name: "submitStockExplain",
    component: () => import("../views/stockManage/components/explain.vue"),
  },
  {
    // 存料管理 提交结算说明页面
    path: "/submitMaterialExplain",
    name: "submitMaterialExplain",
    component: () => import("../views/materialOrder/components/explain.vue"),
  },
  {
    // 存料结算页面详情
    path: "/stockBalance",
    name: "stockBalance",
    component: () => import("../views/stockBalance/index.vue"),
  },
  {
    // 提料结算页面详情
    path: "/materialBalance",
    name: "materialBalance",
    component: () => import("../views/materialOrder/materialBalance.vue"),
  },
  {
    // 存料结算电子单据页面
    path: "/stockBalanceTable",
    name: "stockBalanceTable",
    component: () => import("../views/stockBalance/stockBalanceTable.vue"),
  },
  {
    // 提料结算电子单据页面
    path: "/materialBalanceTable",
    name: "materialBalanceTable",
    component: () => import("../views/materialOrder/components/materialBalanceTable"),
  },
  {
    // 卖料定价详情页面
    path: "/sellPricingDetails",
    name: "sellPricingDetails",
    component: () => import("../views/sellPricingDetails/index.vue"),
  },
  {
    // 首页买料订单页面
    path: "/buyOrder",
    name: "buyOrder",
    component: () => import("../views/buyOrder/index.vue"),
    // meta:{keepneed:true}
  },
  {
    // 手写签名页面
    path: "/autograph",
    name: "autograph",
    component: () => import("../views/sellgold/components/autograph.vue"),
  },
  {
    //   卖料违约结算页面
    path: "/breakContract",
    name: "breakContract",
    component: () => import("../views/breakContract/index.vue"),
  },
  {
    //   卖料违约结算页面
    path: "/default",
    name: "default",
    component: () => import("../views/default/index.vue"),
  },
  {
    //    开户合同页面
    path: "/accountContract",
    name: "AccountContract",
    component: () => import("../views/AccountContract/index.vue"),
  },
  {
    //    占用押金页面
    path: "/occupyDeposit",
    name: "occupyDeposit",
    component: () => import("../views/occupyDeposit/index.vue"),
  },
  {
    //    交押金页面
    path: "/deposit",
    name: "deposit",
    component: () => import("../views/pricingDeposit/deposit.vue"),
  },
  {
    //    交押金提示成功页面
    path: "/depositConfirm",
    name: "depositConfirm",
    component: () => import("../views/pricingDeposit/depositConfirm.vue"),
  },
  {
    //    悬浮窗报价
    path: "/suspends",
    name: "suspends",
    component: () => import("../views/suspends/index.vue"),
  },
  {
    //    悬浮窗报价
    path: "/changeCatgory",
    name: "changeCatgory",
    component: () => import("../views/suspends/changeCatgory.vue"),
  },
  {
    //    相关协议页面
    path: "/Agreements",
      name: "Agreements",
    component: () => import("../views/person/Agreements.vue"),
  },
  {
    //    注册协议页面
    path: "/RegisterAgreements",
    name: "RegisterAgreements",
    component: () => import("../views/person/RegisterAgreements.vue"),
  },
  {
    //    价格提醒页面
    path: "/priceAlert",
    name: "priceAlert",
    component: () => import("../views/priceAlert/index.vue"),
  },
  {
    // 订单报表
    path: "/orderData",
    name: "orderData",
    component: () => import("../views/orderData/index.vue"),
  },
  {
    // 换料订单
    path: "/changeMaterial",
    name: "changeMaterial",
    component: () => import("../views/changeMaterial/index.vue"),
  },
  {
    // 换料订单电子单据
    path: "/changeMaterialTable",
    name: "changeMaterialTable",
    component: () => import("../views/changeMaterial/changeMaterialTable.vue"),
  },
  {
    // 换料订单电子单据
    path: "/wuliaoDetail",
    name: "wuliaoDetail",
    component: () => import("../views/selldetails/wuliaoDetail.vue"),
  },
/*  {
    // 来料入库列表
    path: "/incoming",
    name: "incoming",
    component: () => import("../views/incoming/index.vue"),
  },*/
  {
    // 来料扫码上传图片页面
    path: "/incomingScan",
    name: "incomingScan",
    component: () => import("../views/incomingScan/index.vue"),
  },
  {
    // 来料统计
    path: "/incomingStatistics",
    name: "incomingStatistics",
    component: () => import("../views/incomingManage/incoming/statistics.vue"),
  },
  {
    // 积分明细
    path: "/integral",
    name: "integral",
    component: () => import("../views/integral/index.vue")
  },
  {
    // 积分规则
    path: "/integralRule",
    name: "integralRule",
    component: () => import("../views/integral/integralRule.vue")
  },
  {
    // 批量存料结算
    path: "/mortgageStatement",
    name: "mortgageStatement",
    component: () => import("../views/mortgageStatement/index.vue")
  },
  {
    // 小金库
    path: "/storeroom",
    name: "storeroom",
    component: () => import("../views/storeroom/index.vue")
  },
  {
    // 来料管理
    path: "/incomingManage",
    name: "incomingManage",
    component: () => import("../views/incomingManage/index.vue"),
  },
];


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to,from,next)=>{
  versionTood.isNewVersion();
  if (to.path == '/news') {
      (to.meta as any).keepneed = true
    }else if(to.name=="sellgold" || to.name=='Buygold' || to.name=='mortgagegold'){
        const user = JSON.parse(localStorage.getItem('userinfo')!)
        const isFace = user.hasOwnProperty('faceVerify')
        if (!isFace) {
          next('/face')
        }
  }
  // if (to.name == 'address' && from.name == 'buyOrder' ) {
  //   from.meta.keepneed = true
  // }
// }
  next()
})
export default router;
