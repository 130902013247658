// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/btn_online@2x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".qiyukefu{position:fixed;top:0;right:0;width:8vw;width:3.75rem;height:8vw;height:3.75rem;border-radius:50%;z-index:1!important;background-color:#f84725;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%}.qiyukefu .qiyuweidu{right:0}.qiyukefu .leftqiyu,.qiyukefu .qiyuweidu{position:absolute;top:0;width:4vw;height:4vw;border-radius:50%;text-align:center;line-height:4vw;font-size:1.867vw;background-color:#f84725;color:#fff}.qiyukefu .leftqiyu{left:0}", ""]);
// Exports
module.exports = exports;
