
(function (w, d, n, a, j) {
    w[n] = w[n] || function () {
        (w[n].a = w[n].a || []).push(arguments);
    };
    j = d.createElement('script');
    j.async = true;
    j.src ='https://qiyukf.com/script/d1f7d16c8e2eb0f23e9e38e0fd4fab4a.js';
    d.body.appendChild(j);
})(window, document, 'ysf');

var isSdkReady = false;
var isConfig = false
