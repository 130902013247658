// // 记录加载初始化状态
// let isSdkReady = false
 
// // 记录加载配置状态
// let isConfig = false
 
export default class {
 
  // 初始化
  static init() {
    ysf("onready", function () {
    });
  }
 
// 配置客户参数
  static Config(userInfo) {
    ysf('config', {
        uid: userInfo.uid,
        level : 1, // vip级别
        // staffid:'6188334',//指定客服id
        data: JSON.stringify([{
                "key": "real_name",
                "value": userInfo.realName?userInfo.realName:userInfo.phone
            },
            {
                "key": "mobile_phone",
                "value": userInfo.phone,
                "zone": "true"
             },
             {"key":"avatar","label":"头像","value":"https://yht-open-resource.oss-cn-shenzhen.aliyuncs.com/yhtlogo.jpg"},
            {
                "key": "account",
                "label": "账号",
                "value": userInfo.phone,
                "zone": "true"
            },
        ]),
        success: function () {  
          ysf('open');
            console.log('成功');
        },
        error: function(){       // 错误回调
                // handle error
            console.log('失败');
        }
    });
  }
 
  // 打开客服链接
  static openSdk() {

    // return ysf('url')
    // console.log('成功了');
    ysf('open');
    
      
 
  }
  // 用户登出
  static logout(){
    ysf('logoff')
  }

  //未读消息通知回调

  static  callback(){
      return  ysf('getUnreadMsg')?ysf('getUnreadMsg').total:null
  }
  
}