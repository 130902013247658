"use strict";
/**
 * @file 过滤器
 * @author DJ
 */

const formatDate = (dateStr, formatter = "yyyy-MM-dd") => {
  if (!dateStr || dateStr == "1900-01-01 00:00:00") {
    return "";
  }

  if (typeof dateStr === "string") {
    if (dateStr.indexOf(".") > -1) {
      // 有些日期接口返回带有.0。
      dateStr = dateStr.substring(0, dateStr.indexOf("."));
    }
    // 解决IOS上无法从dateStr parse 到Date类型问题
    dateStr = dateStr.replace(/-/g, "/");
  }
  return new Date(dateStr).Format(formatter);
};

// 格式金额，保留两位小数 最大保留5位
const formatMoney = str => {
  let num = parseFloat(str);
  num = Math.floor(num * 100000) / 100000;

  return (
    num &&
    num.toString().replace(/(\d)(?=(\d{3})+\.)/g, res => {
      return res + ",";
    })
  );
};

// 单据状态
const PayStatus = val => {
  switch (val) {
    case "1":
      return "已付";
    default:
      return "未付";
  }
};

// 取手机号 后四位
const TakePhoneFour = val => {
  val = val.toString().substr(val.length - 4);
  return val;
};

// 取手机号中间隐藏
const PhoneHidden = val => {
  if (Number(val) && String(val).length === 11) {
    const mobile = String(val);
    const reg = /^(\d{3})\d{4}(\d{4})$/;
    return mobile.replace(reg, "$1****$2");
  } else {
    return val;
  }
};

//银行卡每四位 空格 //前几位都是*
const TakeBankFour = val => {
  val = "****************" + val.substr(val.length - 4, val.length);
  return val.replace(/(.{4})/g, "$1 ");
};

//取银行号 后四位
const BankOnlyFour = val => {
  return (
    val.toString().substr(0, 4) +
    " **** **** " +
    val.toString().substr(val.length - 4)
  );
};

//判断时间-是不是今天
const TimeIsTaday = val => {
  if (new Date(val).getDate() == new Date().getDate()) {
    return "今天" + " " + formatDate(val, "hh:mm:ss");
  } else {
    return formatDate(val, "yyyy-MM-dd hh:mm:ss");
  }
};

//距离m转换km
const Distance = val => {
  if (val >= 1000) {
    return (val * 0.001).toFixed(2) + "km";
  } else {
    return parseInt(val) + "m";
  }
};

// 格式金额，保留1位小数
const formatMoneyOne = str => {
  const num = parseFloat(str).toFixed(1);

  return (
    num &&
    num.toString().replace(/(\d)(?=(\d{3})+\.)/g, res => {
      return res + ",";
    })
  );
};

//银行卡每四位 空格 //前几位都是*
const TakeNumberFour = val => {
  if (val) val = val + "";
  else return "";
  return val.replace(/(.{4})/g, "$1 ");
};

// 直辖市过滤
const formatCity = str => {
  if (str == "北京市" || str == "天津市" || str == "上海市" || str == "重庆市")
    return "";
  return str;
};

const substrlen = str => {
  return str.substring(str.length - 2);
};

const stepshow = str => {
  if (str.StatusText == "未开始") {
    return str.StatusText;
  }
  if (str.StatusText == "正常" && str.CompletionStepCount != 0) {
    return "步骤" + str.CompletionStepCount;
  } else if (str.StatusText == "正常" && str.CompletionStepCount == 0) {
    return str.StatusText;
  }
  if (str.StatusText == "即将到期" && str.CompletionStepCount != 0) {
    return "步骤" + str.CompletionStepCount;
  } else if (str.StatusText == "即将到期" && str.CompletionStepCount == 0) {
    return str.StatusText;
  }
  if (str.StatusText == "超时" && str.CompletionStepCount != 0) {
    return "步骤" + str.CompletionStepCount;
  } else if (str.StatusText == "超时" && str.CompletionStepCount == 0) {
    return str.StatusText;
  }
  if (str.StatusText == "中止" && str.CompletionStepCount != 0) {
    return "步骤" + str.CompletionStepCount;
  } else if (str.StatusText == "中止" && str.CompletionStepCount == 0) {
    return str.StatusText;
  }
  if (str.StatusText == "完成") {
    return str.StatusText;
  }
};

//身份证 名字+ * --frontLen：前面保留位数，endLen：后面保留位数。
const plusXing = (str, frontLen, endLen) => {
  if (str.length > 0) str = str + "";
  const len = str.length - frontLen - endLen;
  let xing = "";
  for (let i = 0; i < len; i++) {
    xing += "*";
  }
  return str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
};
// 时间戳转化
const formatDate_ = (date_, fmt_) => {
  const date = new Date(date_ * 1000);
  let fmt = fmt_ || "yyyy-MM-dd hh:mm:ss";
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  const o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds()
  };
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + "";
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : ("00" + str).substr(str.length)
      );
    }
  }
  return fmt;
};
// 导入 moment
import moment from 'moment'

// 将时间进行格式化
const formatTime = function (value) {
  return moment(value).format('MM.DD HH:mm')
}
const formatTimeYear = function (value) {
  return moment(value).format('yyyy.MM.DD')
}
export {
  formatDate_,
  stepshow,
  substrlen,
  formatDate,
  formatMoney,
  PayStatus,
  TakePhoneFour,
  TakeBankFour,
  PhoneHidden,
  BankOnlyFour,
  TimeIsTaday,
  Distance,
  formatMoneyOne,
  TakeNumberFour,
  formatCity,
  plusXing,
  formatTime,
  formatTimeYear
};
