var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showfooter
    ? _c("div", { staticClass: "footerbox" }, [
        _c(
          "div",
          { staticClass: "flex-around" },
          _vm._l(_vm.list, function(items, index) {
            return _c(
              "div",
              {
                key: items.name,
                class: index == _vm.activeIndex ? "active" : "",
                on: {
                  click: function($event) {
                    return _vm.activepage(index, items.urlname)
                  }
                }
              },
              [
                index == _vm.activeIndex
                  ? [
                      _c("div", { staticClass: "footerboximg" }, [
                        _c("img", { attrs: { src: items.actimgurl, alt: "" } })
                      ])
                    ]
                  : [
                      _c("div", { staticClass: "footerboximg" }, [
                        _c("img", { attrs: { src: items.imgurl, alt: "" } })
                      ])
                    ],
                _c("div", [_vm._v(_vm._s(items.name))])
              ],
              2
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }