<template>
  <div>
        <div class="qiyukefu"
      ref="qiyu"
      :style="{'left': x + 'px', 'top': y + 'px' }"
      @click="openKefu" 
      @mousedown.stop="dragStartHandler"
      @touchstart.stop="dragStartHandler"
      @mousemove.stop="draggingHandler"
      @touchmove.stop="draggingHandler"
      @mouseup.stop="dragEndHandler"
      @touchend.stop="dragEndHandler"
        >
       <div v-show="leftisShow">
         <div class="leftqiyu" v-if="isShow?isShow:qiyuweidu">{{qiyuweidu?qiyuweidu:'1'}}</div>
       </div>

       <div v-show="rightisShow">
          <div class="qiyuweidu" v-if="isShow?isShow:qiyuweidu">{{qiyuweidu?qiyuweidu:'1'}}</div>
       </div>
       
       <!-- <div class="qiyuweidu" v-else v-show="qiyuweidu != 0">{{qiyuweidu}}</div> -->
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
      isShow:JSON.parse(localStorage.getItem('isSevrice')),
      leftisShow:false,
      rightisShow:true,
    // 七鱼未读消息数量
      qiyuweidu:+'1',
    // 打开七鱼的配置信息
      qiyuInfo:{},
    //获取手指初始坐标
	  startX:0,
	  startY:0,
      // x轴坐标
      x: 0,
      // y轴坐标
      y:150,
      // 元素宽度
      w:+''
        }
    },
    methods:{
      // touchstart 当手指触摸屏幕时候触发
     dragStartHandler (e) {
      //  e.preventDefault()
      let touch = e
      if (e.touches) {
        touch = e.touches[0]
      }
      this.startX = touch.clientX - e.target.offsetLeft
      this.startY = touch.clientY - e.target.offsetTop
    },
    //  touchmove 当手指在屏幕上滑动的时候连续地触发
     draggingHandler (e) {
      e.preventDefault()
      this.w = e.target.clientWidth
      let touch = e
      if (e.touches) {
        touch = e.touches[0]
      }
      // 设置拖拽元素的位置
      this.x = touch.clientX - this.startX
      this.y = touch.clientY -  this.startY
      // 限制元素不能超过屏幕

      // 元素超出左边屏幕10的距离
      if (this.x < -10 ) {
        // 元素超出的距离就等于当前元素宽度的一半
        this.x = -e.target.clientWidth/2
      } else if( this.x >-9 && this.x < 0){   //如果元素超出屏幕且不超过9的距离
        this.x = 0  //元素不能超出屏幕
      }
      //如果元素超出右边屏幕的距离
       else if (this.x > window.screen.width - e.target.clientWidth +e.target.clientWidth/2 - 3) {
        //  元素超出的距离就等于当前元素宽度的一半
        this.x = window.screen.width - e.target.clientWidth + e.target.clientWidth/2
        // 未读消息左侧隐藏 右侧的显示
        this.rightisShow = false
        this.leftisShow = true
      } else if (this.x < window.screen.width - e.target.clientWidth +e.target.clientWidth/2 - 3){ //如果元素不超出右边屏幕的距离
      // 未读消息右侧隐藏  左侧显示
        this.rightisShow = true
        this.leftisShow = false
      }
      // 元素超出屏幕高度
      if (this.y < 0) {
        this.y = 0
      } else if (this.y >  window.screen.height -  e.target.clientHeight - 50) {  //限制元素不能超出的屏幕底部距离
        this.y = window.screen.height -  e.target.clientHeight - 50
      }
    },
    // touchcancel当系统停止跟踪触摸的时候触发
     dragEndHandler (e) {
      // e.preventDefault()
      if (this.x < -10) {
           this.x = -e.target.clientWidth/2
      }
      else if(this.x  > window.screen.width - e.target.clientWidth + 10){
          this.x = window.screen.width - e.target.clientWidth + e.target.clientWidth/2
          this.rightisShow = false
          this.leftisShow = true
      }
      else if(this.x < window.screen.width/2 - e.target.clientWidth/2){
            this.x = 0
      }else if (this.x >window.screen.width/2 - e.target.clientWidth/2 ){
            this.x =  window.screen.width - e.target.clientWidth
      }
    },


    // 打开客服聊天窗口
    openKefu(){
        this.x = 0
  
        localStorage.setItem('isSevrice',0)
        //   初始化七鱼sdk
       this.fishkefu.init() ;
        // 打开七鱼客服接口
       this.qiyuInfo = JSON.parse( localStorage.getItem('userinfo'))

        if(Object.prototype.toString.call(this.qiyuInfo)==='[object Object]'){
                this.fishkefu.Config(this.qiyuInfo)
                this.fishkefu.openSdk();
            }else{
                this.fishkefu.openSdk();
          }
        }
      
   
    },
    mounted(){
      // 给元素在x轴设置默认值
      let div =  document.querySelector(".qiyukefu")
      this.w =  div.offsetWidth/2
      this.x =  window.screen.width -  window.screen.width -this.w
    },
    created(){

  

           // 获取七鱼未读消息数量
      this.qiyuweidu = this.fishkefu.callback()
  },
}
</script>

<style lang="scss">
.qiyukefu{
  position: fixed;
  top: 0;
  right: 0;
  width: 3.75rem;
  height:3.75rem;
  border-radius: 50%;
  z-index: 2 !important;
  background-color: #f84725;
  background: url("~@/assets/images/btn_online@2x.png") no-repeat ;
  background-size:100% 100% ;
   .qiyuweidu{
      position: absolute;
      top: 0px;
      right: 0px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      font-size: 14px;
      background-color:#f84725;
      color: #fff;
    }
  .leftqiyu{
     position: absolute;
      top: 0px;
      left: 0px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      font-size: 14px;
      background-color:#f84725;
      color: #fff;
    }
}
</style>