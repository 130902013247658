var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        ref: "qiyu",
        staticClass: "qiyukefu",
        style: { left: _vm.x + "px", top: _vm.y + "px" },
        on: {
          click: _vm.openKefu,
          mousedown: function($event) {
            $event.stopPropagation()
            return _vm.dragStartHandler($event)
          },
          touchstart: function($event) {
            $event.stopPropagation()
            return _vm.dragStartHandler($event)
          },
          mousemove: function($event) {
            $event.stopPropagation()
            return _vm.draggingHandler($event)
          },
          touchmove: function($event) {
            $event.stopPropagation()
            return _vm.draggingHandler($event)
          },
          mouseup: function($event) {
            $event.stopPropagation()
            return _vm.dragEndHandler($event)
          },
          touchend: function($event) {
            $event.stopPropagation()
            return _vm.dragEndHandler($event)
          }
        }
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.leftisShow,
                expression: "leftisShow"
              }
            ]
          },
          [
            (_vm.isShow
            ? _vm.isShow
            : _vm.qiyuweidu)
              ? _c("div", { staticClass: "leftqiyu" }, [
                  _vm._v(_vm._s(_vm.qiyuweidu ? _vm.qiyuweidu : "1"))
                ])
              : _vm._e()
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.rightisShow,
                expression: "rightisShow"
              }
            ]
          },
          [
            (_vm.isShow
            ? _vm.isShow
            : _vm.qiyuweidu)
              ? _c("div", { staticClass: "qiyuweidu" }, [
                  _vm._v(_vm._s(_vm.qiyuweidu ? _vm.qiyuweidu : "1"))
                ])
              : _vm._e()
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }