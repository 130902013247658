var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadShow
    ? _c(
        "div",
        {
          staticClass: "LoadBox",
          staticStyle: { zIndex: "9999" },
          on: {
            mousewheel: function($event) {
              $event.preventDefault()
            },
            touchmove: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("div", { staticClass: "loadingBox" }, [
            _vm._m(0),
            _c("div", { staticClass: "loadText" }, [
              _vm._v(_vm._s(_vm.loadText))
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loading" }, [
      _c("span"),
      _c("span"),
      _c("span")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }