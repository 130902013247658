import axios from "axios";

const isNewVersion = () => {
  axios.get("version.json").then(res => {
    const version = res.data.version;
    console.log("version:", version);
    const localVersion = localStorage.getItem("projectVersion");
    console.log("localVersion:", localVersion, typeof localVersion);
    if (!localVersion || version.toString() !== localVersion) {
      console.log("version update");
      window.location.reload();
      localStorage.setItem("projectVersion", version);
    }
  });
};
export default {
  isNewVersion
};
