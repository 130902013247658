var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loadShow
      ? _c(
          "div",
          {
            ref: "qiyu",
            staticClass: "suspends",
            style: { left: _vm.x + "px", top: _vm.y + "px" },
            on: {
              click: _vm.changePrice,
              mousedown: function($event) {
                $event.stopPropagation()
                return _vm.dragStartHandler($event)
              },
              touchstart: function($event) {
                $event.stopPropagation()
                return _vm.dragStartHandler($event)
              },
              mousemove: function($event) {
                $event.stopPropagation()
                return _vm.draggingHandler($event)
              },
              touchmove: function($event) {
                $event.stopPropagation()
                return _vm.draggingHandler($event)
              },
              mouseup: function($event) {
                $event.stopPropagation()
                return _vm.dragEndHandler($event)
              },
              touchend: function($event) {
                $event.stopPropagation()
                return _vm.dragEndHandler($event)
              }
            }
          },
          [
            _c("p", [
              _vm._v(_vm._s(_vm.initPriceName == "LON_Au" ? "伦敦金" : "黄金"))
            ]),
            _c("br"),
            _c("p", { staticClass: "fontWeight" }, [
              _vm._v(_vm._s(_vm.price.toFixed(2)))
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }