import Vue from 'vue';
import Loading from'../components/Loadings.vue'

let $app = Vue.extend(Loading) //在 vue 中注册loading组件
let $Loading = new $app().$mount(document.createElement('div'))
// 创建 loading 实例并挂载 vue 实例上
document.body.appendChild($Loading.$el)

export default {
  install (vm) {
    vm.prototype.$Loading = {
      show: (options) => {
        $Loading.loadText = options.text
        $Loading.loadShow = true
      },
      close: () => {
        $Loading.loadShow = false
      }
    }
  }
}
