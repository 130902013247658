import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
      orderInfo: JSON.parse(localStorage.getItem('buyOrderInfo') as string) ||{},
      suspend:JSON.parse(localStorage.getItem('suspend')!) || true,
      suspendCatgory:JSON.parse(localStorage.getItem('changeCatgory')!) || [{name:'伦敦金',id:'LON_Au',def:true},
        {name:'黄金',id:'huangjin9999',def:false}],
      infoPriceName:localStorage.getItem('changeCatgoryName') || 'LON_Au'
  },
  mutations: {
      addOrderInfo(state, payload){
        state.orderInfo = payload
      },
      changeSuspend(state, payload){
        state.suspend = payload
      },
      changeSuspendCatgory(state, payload){
        state.suspendCatgory = payload
      },
      changeInfoPriceName(state, payload){
        state.infoPriceName = payload
      }
  },
  actions: {
    getAsyncOrderInfo(context, payload){
      context.commit('addOrderInfo',payload)
    },
    getchangeSuspend(context, payload){
      context.commit('changeSuspend',payload)
      localStorage.setItem('suspend',payload)
    },
    getchangeSuspendCatgory(context, payload){
      context.commit('changeSuspendCatgory',payload)
      localStorage.setItem('changeCatgory',JSON.stringify(payload))
    },
    getchangeInfoPriceName(context, payload){
      context.commit('changeInfoPriceName',payload)
      localStorage.setItem('changeCatgoryName',payload)
    }
  },
  modules: {}
});
