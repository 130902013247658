<template>
   <div class="LoadBox" style="zIndex:9999" v-if="loadShow" @mousewheel.prevent @touchmove.prevent>
        <div class="loadingBox" >
        <div class="loading">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="loadText">{{loadText}}</div>
      </div>
   </div>
</template>

<script>
import '@/utils/Loadings'
export default {
    props:{
        loadText:String,
        loadShow:Boolean
    }
}
</script>

<style lang="scss" scoped>
.loadingBox{
        width: 360px;
        height: 200px;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 600px;
        left: 50%;
        margin-left: -180px;
        border-radius: 12px;
}
.loading {
  width: 150px;
  height: 18px;
  margin-left: 130px;
  margin-top: 40px;
}

.loadText{
        color: #fff;
        width: 100%;
        margin-top: 50px;
        font-size: 32px;
        text-align: center;
}
.loading span {
  display: inline-block;
  width: 18px;
  height: 100%;
  margin-right: 16px;
  border-radius: 50%;
  background: #fff;
  -webkit-animation: load 1.04s ease infinite;
}
.loading span:last-child {
  margin-right: 0px;
}
.LoadBox{
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    overflow: hidden;
}
@-webkit-keyframes load {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.loading span:nth-child(1) {
  -webkit-animation-delay: 0.13s;
}
.loading span:nth-child(2) {
  -webkit-animation-delay: 0.26s;
}
.loading span:nth-child(3) {
  -webkit-animation-delay: 0.39s;
}
.loading span:nth-child(4) {
  -webkit-animation-delay: 0.52s;
}
.loading span:nth-child(5) {
  -webkit-animation-delay: 0.65s;
}
</style>